
.sendbird-channel-header__title__right__user-id {
    display: none !important;
}

.sendbird-channel-header__title__right {
    padding: 0 !important;
    display: flex;
    align-items: center;
}

.sendbird__user-profile-userId {
    display: none !important;
}

.sendbird__user-profile-separator {
    display: none !important;
}

.sendbird-channel-list__body > div > div:hover {
    background-color: #f5f5f5;
}

.sendbird-place-holder:hover {
    background-color: white !important;
}

.sendbird__user-profile-avatar {
    display: none;
}

.sendbird-message-content__left.incoming {
    display: none !important;
}

.sendbird-message-hoc__message-content {
    padding: 8px 0px;
}

.sendbird-message-content__middle__sender-name {
    margin-left: 0px !important;
}

.sendbird-conversation {
    border-top: none !important;
}

.sendbird-modal {
    z-index: 1230;
}

.sendbird-dropdown__reaction-bar {
    z-index: 1230;
}

.sendbird-members-accordion__member-avatar {
    display: none !important;
}

.sendbird-members-accordion__footer__invite-users {
    margin-left: 40px;
}

.sendbird-user-list-item__avatar {
    display: none;
}

.sendbird-user-list-item__title {
    margin-left: -48px;
}
